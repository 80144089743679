import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext
} from "react";
import {
  makeStyles,
  Typography,
  Button,
  MenuItem,
  Link
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useAppState } from "../../../state";
import UserAvatar from "./UserAvatar/UserAvatar";
import BellIcon from "../../../icons/BellIcon";
import Menu from "@material-ui/core/Menu";
import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import { SyncClient } from "twilio-sync";
import TokenContextProvider from "../../../TokenContext";

// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";

import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  userContainer: {
    position: "relative",
    top: 0,
    background: "#fff",
    right: 0,
    margin: 0,
    display: "flex",
    alignItems: "center",
    columnGap: "15px",
    width: "100%",
    padding: "1em 4em",
    justifyContent: "left",
    borderBottom: "2px solid #f50257",
    fontWeight: "bold",
    fontSize: "17px",
    marginBottom: "0"
  },
  disapproveBtn: {
    background: "red",
    color: "#fff"
  },
  bellContainer: {
    cursor: "pointer"
  },
  requestDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5px 0"
  },
  dailogTitle: { color: "green", textAlign: "center" },
  approveBtn: {
    background: "green",
    color: "#fff"
  },
  userButton: {
    color: "#000"
  },
  userIcon: {
    position: "absolute!important" as any,
    right: 15
  },
  myRoom: {},
  logoutLink: {
    color: "#000",
    cursor: "pointer",
    padding: "10px 20px"
  },
  menuBar: {
    marginTop: "55px"
  },
  profileIcon: {
    width: "60px!important" as any,
    height: "60px!important" as any,
    marginRight: "10px"
  }
});

const UserMenu: React.FC = () => {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();
  const { user, signOut } = useAppState();
  const { localTracks } = useVideoContext();
  let syncClient: any;

  const [open, setOpen] = React.useState(false);
  const [syncObject, setSyncObject] = useState<any>(null);
  const [newRequest, setNewRequest] = useState<any>([]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const roomName: any =
    localStorage?.getItem("roomName") != ""
      ? localStorage?.getItem("roomName")
      : "test";
  const UserName = localStorage.getItem("name");
  const UserEmail = localStorage.getItem("email");
  const UserNumber = localStorage.getItem("phone");

  // const rejectInvitations = useCallback(
  //   (index: any, rowIndex: any) => {
  //     setOpen(false);
  //     // syncObject.list(roomName).then(function(list: any) {
  //     const mutatorFunction = (currentValue: any) => {
  //       currentValue.status = 2;
  //       return currentValue;
  //     };
  //     syncObject
  //       .mutate(index, mutatorFunction, { ttl: 86400 })
  //       .then((item: any) => {
  //         setNewRequest((current: any) =>
  //           current.filter((element: any, index: any) => {
  //             return index !== rowIndex;
  //           })
  //         );
  //         // if (newRequest.length < 1) {
  //         //   setOpen(false);
  //         // }
  //       })
  //       .catch((error: any) => {
  //         console.error("List Item mutate() failed", error);
  //       });
  //   },
  //   [syncObject, setNewRequest, newRequest]
  // );
  // const acceptInvitations = useCallback(
  //   (index: any, rowIndex: any) => {
  //     // setOpen(false);
  //     // syncObject.list(roomName).then(function(list: any) {
  //     const mutatorFunction = (currentValue: any) => {
  //       currentValue.status = 1;
  //       return currentValue;
  //     };
  //     syncObject
  //       .mutate(index, mutatorFunction, { ttl: 86400 })
  //       .then((item: any) => {
  //         setNewRequest((current: any) =>
  //           current.filter((element: any, index: any) => {
  //             return index !== rowIndex;
  //           })
  //         );
  //         // if (newRequest.length < 1) {
  //         //   setOpen(false);
  //         // }
  //       })
  //       .catch((error: any) => {
  //         console.error("List Item mutate() failed", error);
  //       });
  //     // });
  //   },
  //   [syncObject, newRequest, setNewRequest]
  // );

  const [menuOpen, setMenuOpen] = useState(false);
  const [isListSubscribed, setListSubscribed] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { authToken } = useContext(TokenContextProvider);
  const roomLink = "/myroom/";
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleSignOut = () => {
    localTracks.forEach(track => track.stop());

    // Retrieve user ID from local storage
    const userID = localStorage.getItem("userID");

    // Check if userID is not null before proceeding
    if (userID !== null) {
      // Create an object with the expected structure
      const data = { userID: parseInt(userID, 10) }; // Parse the string as an integer if needed

      fetch(`${process.env.REACT_APP_API_URL}api/signout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `${authToken}`
        },
        body: JSON.stringify(data)
      })
        .then(response => {
          if (response.status === 200) {
            localStorage.clear();

            signOut?.();
          } else {
            console.error("Failed to sign out:", response.status);
          }
        })
        .catch(error => {
          console.error("Error signing out:", error);
        });
    } else {
      console.error("'userID' not found in local storage");
    }
  };

  const subsList = () => {
    setListSubscribed(true);
    fetch(
      `${process.env.REACT_APP_API_URL}api/sync/token`,

      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `${authToken}`
        }
      }
    )
      .then(response => response.json())
      .then(data => {
        const roomName: any =
          localStorage?.getItem("roomName") != ""
            ? localStorage?.getItem("roomName")
            : "test";
        //props.startLoading(false);

        const syncClient2 = new SyncClient(data.token);

        syncClient2
          .list(roomName)
          .then(function (list) {
            setSyncObject(list);
            const pageHandler = (paginator: any) => {
              paginator.items.forEach((item: any) => {
                if (!item.isLocal && item.data.status == 0) {
                  setNewRequest((oldd: any) => [...oldd, item]);
                  setOpen(true);
                } else {
                  list.remove(item.index);
                }
              });
              return paginator.hasNextPage
                ? paginator.nextPage().then(pageHandler)
                : null;
            };
            list
              .getItems({ from: 0, order: "asc", limit: 10 })
              .then(pageHandler)
              .then(() => {
                // if (newRequest.length > 0) setOpen(true);
              })
              .catch(error => {
                console.error("List getItems() failed", error);
              });

            list.on("itemAdded", args => {
              if (!args.isLocal) {
                // newRequest.push(args.item);
                setNewRequest((oldd: any) => [...oldd, args.item]);
                // setNewRequest([args.item]);
              }
              setOpen(true);
            });
          })
          .catch(function (error) {
            console.log("Unexpected error", error);
          });
      });
  };

  useEffect(() => {
    if (!isListSubscribed) {
      subsList();
    }
    if (newRequest.length < 1) {
      setOpen(false);
    }
    console.log("newRequestnewRequest", newRequest, newRequest.length);
  }, [subsList, setOpen, newRequest]);

  if (process.env.REACT_APP_SET_AUTH === "passcode") {
    return (
      <div className={classes.userContainer}>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className={classes.dailogTitle}>
            {"!! Video Join Request !!"}
          </DialogTitle>
          <DialogContent style={{ width: 430 }}>
            <DialogContentText id="alert-dialog-slide-description">
              {newRequest && newRequest.length > 0 ? (
                <>
                  <div className={classes.requestDiv}>
                    There is a new request in your room please join your room
                    and check it.
                  </div>
                </>
              ) : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Link href="/myroom" className={classes.logoutLink}>
              <Button
                variant="contained"
                color="primary"
                type="button"
                id="clear"
              // className={classes.continueButton}
              >
                Join My Room
              </Button>
            </Link>
          </DialogActions>
        </Dialog>

        <Link href="/dashboard" className={classes.logoutLink}>
          Dashboard
        </Link>
        <Link href={roomLink}>
          <Button
            className="whitespace-nowrap"
            variant="contained"
            color="secondary"
            type="button"
          >
            Go to My room
          </Button>
        </Link>
        <IconButton
          onClick={handleClick}
          className={classes.userIcon}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          onClick={handleMenuClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleClose} style={{ lineHeight: "1.4" }}>
            <Avatar className={classes.profileIcon} />
            <Typography variant="caption">
              <p style={{ fontSize: "18px", margin: 0 }}>{UserName}</p>
              {UserNumber}
              <br />
              {UserEmail}
            </Typography>
          </MenuItem>
          <Divider />
          {localStorage.getItem("userRole") == "Admin" && (
            <MenuItem onClick={handleClose}>
              <Link
                href="/settings"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </Link>
            </MenuItem>
          )}
          <MenuItem>
            <Link
              href="/change-password"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Change Password
            </Link>
          </MenuItem>
          <MenuItem onClick={handleSignOut}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
        <div
          className={classes.bellContainer}
          onClick={(event: any) => {
            setOpen(true);
          }}
        >
          <BellIcon />
        </div>

        {localStorage.getItem("userRole") == "Admin" ? (
          <>
            <div className="align-right-menu">
              <Link href="/providers">
                <Button
                  className="whitespace-nowrap"
                  variant="contained"
                  color="secondary"
                  type="button"
                >
                  Manage Providers
                </Button>
              </Link>
              <Link href="/rooms">
                <Button
                  className="whitespace-nowrap"
                  variant="contained"
                  color="secondary"
                  type="button"
                >
                  Manage Rooms
                </Button>
              </Link>
              {/* <Link href="/settings">
                <Button
                  className="whitespace-nowrap"
                  variant="contained"
                  color="secondary"
                  type="button"
                >
                  Twilio Settings
                </Button>
              </Link> */}
            </div>
          </>
        ) : null}
      </div>
    );
  }

  if (process.env.REACT_APP_SET_AUTH === "firebase") {
    return (
      <div className={classes.userContainer}>
        <UserAvatar user={user} />
        <Button
          onClick={() => setMenuOpen(isOpen => !isOpen)}
          ref={anchorRef}
          className={classes.userButton}
        >
          {user!.displayName}
          <ExpandMoreIcon />
        </Button>
        <Menu
          open={menuOpen}
          onClose={() => setMenuOpen(isOpen => !isOpen)}
          anchorEl={anchorRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <MenuItem onClick={handleSignOut}>
            <Typography variant="body1">Logouts</Typography>
          </MenuItem>
        </Menu>
      </div>
    );
  }

  return null;
};

export default UserMenu;
