import React, { ChangeEvent, useState, FormEvent, useContext } from "react";
import { useAppState } from "../../state";
import Button from "@material-ui/core/Button";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Grid from "@material-ui/core/Grid";
import { InputLabel, Theme } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import myna from "../../images/myna.png";
import LockResetTwoToneIcon from "@mui/icons-material/LockResetTwoTone";
import { useParams, useHistory } from "react-router-dom";
import TokenContextProvider from "../../TokenContext";
const useStyles = makeStyles((theme: Theme) => ({
  errorMessage: {
    color: "red",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 0 10px",
    "& svg": {
      marginRight: "0.4em"
    }
  },
  gutterBottom: {
    marginBottom: "1em"
  },
  passcodeContainer: {
    minHeight: "80px"
  },
  submitButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  loginContainer: {
    maxWidth: "24%",
    marginLeft: "38%",
    marginTop: "10%"
  },
  heading: {
    marginBottom: "15px",
    fontWeight: 500,
    fontSize: "30px"
  },
  headerContainer: {
    minHeight: "50px",
    borderBottom: "2px solid #80808029",
    margin: "9px",
    textAlign: "center"
  },
  forgetPass: {
    textDecoration: "none",
    float: "right",
    padding: "10px",
    color: "#36396c",
    "&:hover": {
      color: "#f50057"
    }
  },

  logo: {
    maxWidth: "8%"
  },
  subHeading: {
    fontSize: "17px",
    paddingBottom: "5px"
  },
  lockIcon: {
    fontSize: "2.5rem !important",
    marginLeft: "40%"
  }
}));

export default function ChangePassword() {
  const history = useHistory();
  const { passToken } = useParams<{ passToken?: string }>();
  const classes = useStyles();
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [authError, setAuthError] = useState<string | null>(null);
  const { authToken } = useContext(TokenContextProvider);
  const { user, signOut } = useAppState();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setAuthError("Passwords do not match");
    } else if (oldPassword == "") {
      setAuthError("Current password is required");
    } else {
      setAuthError(null);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}api/change-password`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: `${authToken}`
            },
            body: JSON.stringify({
              newPassword,
              oldPassword
            })
          }
        );

        if (response.status === 200) {
          // Password reset was successful, handle the success case
          // You can redirect the user to the login page or show a success message.
          setAuthError(
            "Password has been changed, redirecting you to login page"
          );
          setTimeout(() => {
            signOut?.();
            history.push("/login");
          }, 5000);

          // history.push("/login");
          // window.location.href = "/login";
        } else {
          // Password reset failed, handle the error case
          const data = await response.json();
          console.log("datadata", data.message);
          setAuthError(data.message || "Password reset failed");
        }
      } catch (error) {
        console.error("Error resetting password:", error);
        setAuthError("Password reset failed");
      }

      return;
    }
  };

  return (
    <>
      <div className={classes.headerContainer}>
        <a href="/">
          <img src={myna} className={classes.logo} alt="logo" />
        </a>
      </div>

      <div className={classes.loginContainer}>
        <Typography className={classes.heading}>Change Password</Typography>
        <form onSubmit={handleSubmit}>
          {authError && (
            <div className={classes.errorMessage}>
              <Typography variant="caption" className={classes.errorMessage}>
                <ErrorOutlineIcon />
                {authError}
              </Typography>
            </div>
          )}
          <Grid justifyContent="space-between">
            <div className={classes.passcodeContainer}>
              <InputLabel shrink htmlFor="input-passcode">
                Enter current password
              </InputLabel>
              <TextField
                fullWidth
                id="input-passcode"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setOldPassword(e.target.value)
                }
                type="text"
                variant="outlined"
                size="small"
                placeholder="Enter new password"
              />
            </div>
            <div className={classes.passcodeContainer}>
              <InputLabel shrink htmlFor="input-passcode">
                Enter new password
              </InputLabel>
              <TextField
                fullWidth
                id="input-passcode"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setNewPassword(e.target.value)
                }
                type="text"
                variant="outlined"
                size="small"
                placeholder="Enter new password"
              />
            </div>

            <div className={classes.passcodeContainer}>
              <InputLabel shrink htmlFor="input-passcode">
                Confirm new password
              </InputLabel>
              <TextField
                fullWidth
                id="input-passcode"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setConfirmPassword(e.target.value)
                }
                type="password"
                variant="outlined"
                size="small"
                placeholder="Confirm password"
              />
            </div>
          </Grid>

          <Grid container>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={!(confirmPassword.length && newPassword.length)}
              className={classes.submitButton}
            >
              Update Password
            </Button>
          </Grid>
        </form>
      </div>
    </>
  );
}
