import { Room, TwilioError } from "twilio-video";
import { useEffect, useContext } from "react";

import { Callback } from "../../../types";
import TokenContextProvider from "../../../TokenContext";

export default function useHandleRoomDisconnection(
  room: Room | null,
  onError: Callback,
  removeLocalAudioTrack: () => void,
  removeLocalVideoTrack: () => void,
  isSharingScreen: boolean,
  toggleScreenShare: () => void
) {
  useEffect(() => {
    // const { authToken } = useContext(TokenContextProvider);
    const authToken = localStorage.getItem("token");

    if (room) {
      const onDisconnected = (_: Room, error: TwilioError) => {
        if (error) {
          onError(error);
        }

        removeLocalAudioTrack();
        removeLocalVideoTrack();
        if (isSharingScreen) {
          toggleScreenShare();
        }
        if (!authToken) {
          onError(new Error("Provider has removed you from call"));
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      };

      room.on("disconnected", onDisconnected);
      return () => {
        room.off("disconnected", onDisconnected);
      };
    }
  }, [
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharingScreen,
    toggleScreenShare
  ]);
}
