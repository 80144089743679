import React, { ChangeEvent, useState, FormEvent } from "react";
import { useAppState } from "../../state";

import Button from "@material-ui/core/Button";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Grid from "@material-ui/core/Grid";
import { ReactComponent as GoogleLogo } from "./google-logo.svg";
import { InputLabel, Theme } from "@material-ui/core";
import IntroContainer from "../IntroContainer/IntroContainer";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useHistory } from "react-router-dom";
import myna from "../../images/myna.png";
import LockResetTwoToneIcon from "@mui/icons-material/LockResetTwoTone";

const useStyles = makeStyles((theme: Theme) => ({
  googleButton: {
    background: "white",
    color: "rgb(0, 94, 166)",
    borderRadius: "4px",
    border: "2px solid rgb(2, 122, 197)",
    margin: "1.8em 0 0.7em",
    textTransform: "none",
    boxShadow: "none",
    padding: "0.3em 1em",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    "&:hover": {
      background: "white",
      boxShadow: "none"
    }
  },
  errorMessage: {
    color: "red",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "1em 0 0.2em",
    "& svg": {
      marginRight: "0.4em"
    }
  },
  gutterBottom: {
    marginBottom: "1em"
  },
  passcodeContainer: {
    minHeight: "80px"
  },
  submitButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  loginContainer: {
    maxWidth: "24%",
    marginLeft: "38%",
    marginTop: "10%"
  },
  heading: {
    marginBottom: "25px",
    fontWeight: 500,
    fontSize: "30px"
  },
  headerContainer: {
    minHeight: "50px",
    borderBottom: "2px solid #80808029",
    margin: "9px",
    textAlign: "center"
  },
  forgetPass: {
    textDecoration: "none",
    float: "right",
    padding: "10px",
    color: "#36396c",
    "&:hover": {
      color: "#f50057"
    }
  },

  logo: {
    maxWidth: "8%"
  },
  subHeading: {
    fontSize: "17px",
    paddingBottom: "10px"
  },
  lockIcon: {
    fontSize: "2.5rem !important",
    marginLeft: "40%"
  }
}));

export default function LoginPage() {
  const classes = useStyles();
  const { signIn, user, isAuthReady } = useAppState();
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [authError, setAuthError] = useState<Error | null>(null);
  const [isLogin, setIsLogin] = useState(true);

  // const isAuthEnabled = Boolean(process.env.REACT_APP_SET_AUTH);

  const login = () => {
    setAuthError(null);
    signIn?.(userName, password)
      .then(() => {
        history.replace(location?.state?.from || { pathname: "/" });
      })
      .catch(err => setAuthError(err));
  };
  const toggleView = () => {
    setAuthError(null);

    setIsLogin(!isLogin);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login();
  };
  const handleForgetPassSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setAuthError(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/forgot-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            email: userName
          })
        }
      );
      const data = await response.json();
      setAuthError(data);
    } catch (error) {
      console.error("Error resetting password:", error);
    }
    return;
  };

  if (user) {
    history.replace("/dashboard");
  }

  if (!isAuthReady) {
    return null;
  }

  return (
    <>
      <div className={classes.headerContainer}>
        <a href="/">
          <img src={myna} className={classes.logo} alt="logo" />
        </a>
      </div>
      {isLogin ? (
        <div className={classes.loginContainer}>
          {process.env.REACT_APP_SET_AUTH === "firebase" && (
            <>
              <Typography variant="h5" className={classes.gutterBottom}>
                Sign in to join a room
              </Typography>
              <Typography variant="body1">
                Sign in using your Twilio Google Account
              </Typography>
              <Button
                variant="contained"
                className={classes.googleButton}
                onClick={login}
                startIcon={<GoogleLogo />}
              >
                Sign in with Google
              </Button>
            </>
          )}

          <>
            <Typography className={classes.heading}>Login</Typography>
            <form onSubmit={handleSubmit}>
              {authError && (
                <div className={classes.errorMessage}>
                  <Typography
                    variant="caption"
                    className={classes.errorMessage}
                  >
                    <ErrorOutlineIcon />
                    {authError.message}
                  </Typography>
                </div>
              )}
              <Grid justifyContent="space-between">
                <div className={classes.passcodeContainer}>
                  <InputLabel shrink htmlFor="input-passcode">
                    User Name
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="input-passcode"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setUserName(e.target.value)
                    }
                    type="text"
                    variant="outlined"
                    size="small"
                    placeholder="Enter user name"
                  />
                </div>

                <div className={classes.passcodeContainer}>
                  <InputLabel shrink htmlFor="input-passcode">
                    Password
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="input-passcode"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setPassword(e.target.value)
                    }
                    type="password"
                    variant="outlined"
                    size="small"
                    placeholder="Enter password"
                  />
                  <a
                    className={classes.forgetPass}
                    href="#"
                    onClick={toggleView}
                  >
                    Forgot Password?
                  </a>
                </div>
              </Grid>

              <Grid container>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!(password.length && userName.length)}
                  className={classes.submitButton}
                >
                  Submit
                </Button>
              </Grid>
            </form>
          </>
        </div>
      ) : (
        <div className={classes.loginContainer}>
          <>
            <LockResetTwoToneIcon
              className={classes.lockIcon}
            ></LockResetTwoToneIcon>
            <Typography className={classes.heading}>
              Forgot password?
            </Typography>
            <Typography className={classes.subHeading}>
              {" "}
              Get a link to regenerate your password:
            </Typography>
            <form onSubmit={handleForgetPassSubmit}>
              <Grid justifyContent="space-between">
                <div className={classes.passcodeContainer}>
                  <InputLabel shrink htmlFor="input-passcode">
                    Email
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="input-passcode"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setUserName(e.target.value)
                    }
                    type="text"
                    variant="outlined"
                    size="small"
                    placeholder="Enter your email"
                  />
                  <a
                    className={classes.forgetPass}
                    href="#"
                    onClick={toggleView}
                  >
                    Back to login
                  </a>
                </div>
              </Grid>

              <Grid container>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!userName.length}
                  className={classes.submitButton}
                >
                  Submit
                </Button>
              </Grid>
              {authError && (
                <div className={classes.errorMessage}>
                  <Typography
                    variant="caption"
                    className={classes.errorMessage}
                  >
                    <ErrorOutlineIcon />
                    {authError.message}
                  </Typography>
                </div>
              )}
            </form>
          </>
        </div>
      )}
    </>
  );
}
