import { useContext } from 'react';
import { DirectChatContext } from '../../components/ChatWindow/DesktopChatWidow/ConversationChatProvider'


export default function DirectUseChatContext() {
  const context = useContext(DirectChatContext);
  if (!context) {
    throw new Error('useChatContext must be used within a ChatProvider');
  }
  return context;
}
