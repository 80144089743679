import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  FormEvent
} from "react";
import { useAppState } from "../../state";
import UserMenu from "../IntroContainer/UserMenu/UserMenu";
import Button from "@material-ui/core/Button";
import "../../App.css";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { SyncClient } from "twilio-sync";
// const { SyncClient } = require("twilio-sync");
import ChatWindow from "../ChatWindow/ChatWindow";
import { ChatProvider } from "../ChatProvider";
import { Client } from "@twilio/conversations";
import BellIcon from "../../icons/BellIcon";
import useChatContext from "../../hooks/useChatContext/useChatContext";
import {
  FormControl,
  Theme,
  TextField,
  TextareaAutosize,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  InputLabel,
  Link
} from "@material-ui/core";
// import { getUserList, sendInvite, userDetailSuccess, deleteUser } from 'app/store/user/userSlice';
import Typography from "@material-ui/core/Typography";
import UniqueNameContextProvider from "../../UniqueNameContext";
import UniqueNameContext from "../../UniqueNameContext";

import { makeStyles } from "@material-ui/core/styles";
import TokenContextProvider from "../../TokenContext";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
// import TableRow from '@mui/material/TableRow';

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography2 from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

import Slide from "@mui/material/Slide";
import EmailIcon from "@mui/icons-material/Email";
import { TransitionProps } from "@mui/material/transitions";
import { get } from "http";
import "../../App.css"
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme: Theme) => ({
  phonInput: {
    // width: '100%',
    // border: '1px solid #000',
  },
  butttonParent: {
    flexDirection: "row",
    columnGap: "15px",
    alignItems: "center"
  },
  messageBell: {
    minWidth: "25px"
  },
  circle: {
    width: "10px",
    height: "10px",
    backgroundColor: "#5BB75B",
    borderRadius: "50%",
    position: "absolute",
    top: "-3px",
    right: "-3px",
    opacity: 1,
    transition: `opacity ${700 * 0.5}ms ease-in`
  },
  bellContainer: {
    cursor: "pointer"
  },
  background: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgb(40, 42, 43)",
    height: "auto",
    flexDirection: "column"
  },
  dailogTitle: { color: "green", textAlign: "center" },
  container: {
    position: "relative",
    width: "100%",
    flex: "1"
  },
  chatTitle: {
    background: "#027AC5"
  },
  textAreaElem: { width: "100%;" },
  innerContainer: {
    display: "flex",
    height: "auto",
    borderRadius: "0",
    // boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
    // overflow: 'hidden',
    position: "relative",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      height: "auto",
      width: "calc(100% - 40px)",
      margin: "auto",
      maxWidth: "400px"
    }
  },
  swooshContainer: {
    background: "white",
    width: "100%",
    padding: "1em 4em",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      padding: "2em"
    }
  },
  continueButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  unreadIconMsg: {
    position: "relative"
  },
  googleButton: {
    background: "white",
    color: "rgb(0, 94, 166)",
    borderRadius: "4px",
    border: "2px solid rgb(2, 122, 197)",
    margin: "1.8em 0 0.7em",
    textTransform: "none",
    boxShadow: "none",
    padding: "0.3em 1em",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    "&:hover": {
      background: "white",
      boxShadow: "none"
    }
  },
  textFieldContainer: {
    width: "100%",
    display: "flex",
    columnGap: "15px",
    alignItems: "center"
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1.5em 0 3.5em",
    "& div:not(:last-child)": {
      marginRight: "1em"
    },
    [theme.breakpoints.down("sm")]: {
      margin: "1.5em 0 2em"
    }
  },
  errorMessage: {
    color: "red",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "1em 0 0.2em",
    "& svg": {
      marginRight: "0.4em"
    }
  },
  gutterBottom: {
    marginBottom: "1em"
  },
  sendSms: {
    width: "140px",
    height: "40px"
  },
  passcodeContainer: {
    minHeight: "120px"
  },
  submitButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  }
}));

const columns: any = [
  { id: "name", label: "Name", minWidth: 170 },
  {
    id: "population",
    label: "Population",
    minWidth: 170,
    align: "right"
  },
  {
    id: "size",
    label: "Size",
    minWidth: 170,
    align: "right"
  },
  {
    id: "action",
    label: "Action",
    minWidth: 170,
    align: "right"
  }
];

const rows: any = [];

export default function UserPage() {
  const alert = useAlert();
  const classes = useStyles();
  const { user, isAuthReady } = useAppState();
  const [filterName, setFilterName] = useState<any>("");
  const { getToken, isFetching } = useAppState();
  const [restBtn, setRestBtn] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [conversationList, setConversationList] = useState<any>([]);
  const [directPhone, setDirectPhone] = useState<any>("+1");
  const { authToken } = useContext(TokenContextProvider);
  const token = localStorage.getItem("token");
  const [chatClient, setChatClient] = useState<Client>();
  const [currentChatPerson, setCurrentChatPerson] = useState<Client>();
  const [selectedConv, setSelectedConv] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [updateUnreadMsg, setUpdateUnreadMsg] = useState<string>("");
  const [loader, setLoader] = useState<any>(false)

  const UserID = localStorage.getItem("userID");

  const [elementSendSms, setElementSendSms] = useState<{
    message: string;
    phone: string;
  }>({
    message: "",
    phone: "+1"
  });

  // const { connect: chatConnect } = useChatContext();
  const { uniqueNameC, updateUniqueNameC } = useContext(
    UniqueNameContextProvider
  );
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (authToken) {
      fetch(
        `${process.env.REACT_APP_API_URL}api/send-sms`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `${authToken}`
          },
          body: JSON.stringify(elementSendSms)
        }
      )
        .then(response => response.json())
        .then(data => {
          if (data.status) {
            setElementSendSms({ message: "", phone: "" });
            setShowSmsBox(false);
            alert.show("SMS has been sent", {
              timeout: 2000, // custom timeout just for this one alert
              type: "success"
            });
          }
        });
    }

    // const sendData = {
    //   firstName: formElements.firstName,
    //   lastName: formElements.lastName,
    //   participant_number: formElements.phone,
    //   email: formElements.email,
    // };
    // addUserAction(sendData);
  };

  const getuserList = useCallback(() => {
    if (authToken && !isLoadingList) {
      setIsLoadingList(true);
      setLoader(true)
      setChatClient(undefined);
      fetch(
        `${process.env.REACT_APP_API_URL}api/conversations-list`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `${authToken}`
          },
          body: JSON.stringify({
            skip: page,
            take: rowsPerPage
          })
        }
      )
        .then(response => response.json())
        .then(data => {
          if (data.convToken) {
            setTotalCount(data.count);
            const client = new Client(data.convToken);
            setChatClient(client);
            const handleClientInitialized = (state: string) => {
              console.log("clitnt is initialized here ===>>");
              if (state === "initialized") {
                console.log("clitnt is initialized here === >>  22");
                setConversationList([]);
                setLoader(false)
                let data2 = data.data.map((record: any) => {
                  getUneadMessages(client, record.conv_unique_name)
                    .then((unreadMsg: any) => {

                      record.unreadMessage =
                        unreadMsg == null || 0 ? false : true;
                      record.unreadMsg = unreadMsg;
                      setConversationList((prevState: any) => [
                        ...prevState,
                        record
                      ]);
                    })
                    .catch((err: any) => {
                      setLoader(false)
                      console.log("error herer", err);
                      record.unreadMessage = false;
                      record.unreadMsg = false;
                      setConversationList((prevState: any) => [
                        ...prevState,
                        record
                      ]);
                    });
                });
              } else if (state === "failed") {
                console.log(
                  "There was a problem connecting to Twilio's conversation service."
                );
              }
              setIsLoadingList(false);
            };
            client.on("messageAdded", messageInfo => {
              console.log(
                "message is addee==>>+++++",
                messageInfo.author,
                messageInfo,
                messageInfo.body
              );
              let messageUserIdentity = messageInfo.author;
              if (
                messageUserIdentity &&
                UserID != messageUserIdentity &&
                messageInfo.body != ""
              ) {
                console.log(
                  "list isss callleed now -------",
                  messageInfo?.conversation?.sid
                );
                setUpdateUnreadMsg(messageInfo?.conversation?.sid);
                // getuserList();
              }
            });
            client.on("initFailed", ({ error }) => {
              setLoader(false)
              console.log("errrorr", error);
            });
            client.on("stateChanged", handleClientInitialized);

            return () => {
              client.off("stateChanged", handleClientInitialized);
            };
          }
        });
    }
  }, [page, rowsPerPage, authToken]);

  const updateUnreadMessage = useCallback(
    convID => {
      console.log("in update unread func", conversationList);
      setConversationList(
        conversationList.map((val: any) => {
          console.log(
            "val.conversations_id === convIDval.conversations_id === convID",
            val.conversations_id,
            convID,
            selectedConv
          );
          if (val.conversations_id === convID && selectedConv != convID) {
            return {
              ...val,
              unreadMsg: true
            };
          }
          return val;
        })
      );
    },
    [conversationList, selectedConv]
  );

  const getUneadMessages = useCallback((client, convUniqueName) => {
    return client
      .getConversationByUniqueName(convUniqueName)
      .then((newConversation: any) => {
        // newConversation.setAllMessagesUnread();
        return newConversation.getUnreadMessagesCount().then((msg: any) => {
          return msg;
        });
      });
  }, []);

  const searchConv = useCallback(
    event => {
      event.preventDefault();
      if (authToken) {
        setLoader(true)
        fetch(
          `${process.env.REACT_APP_API_URL}api/conversations-list`,

          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: `${authToken}`
            },
            body: JSON.stringify({
              skip: page,
              take: rowsPerPage,
              s: filterName
            })
          }
        )
          .then(response => response.json())
          .then(data => {
            //props.startLoading(false);

            setConversationList([]);

            let data2 = data.data.map((record: any) => {
              getUneadMessages(chatClient, record.conv_unique_name).then(
                (unreadMsg: any) => {
                  record.unreadMessage = unreadMsg == null || 0 ? false : true;
                  record.unreadMsg = unreadMsg;
                  setConversationList((prevState: any) => [
                    ...prevState,
                    record
                  ]);
                }
              );
            });
            setLoader(false)
            // setConversationList(data);
          })
          .catch(error => {
            console.log(error);

          })
      }
    },
    [page, rowsPerPage, authToken, filterName, chatClient]
  );

  const deleteUserAction = useCallback(
    (id: any) => {
      if (authToken) {
        fetch(
          `${process.env.REACT_APP_API_URL}api/conversations-list/${id}`,

          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              authorization: `${authToken}`
            }
          }
        )
          .then(response => response.json())
          .then(data => {
            if (data.status == 200) {
              getuserList();
            }
          });
      }
    },
    [page, rowsPerPage, authToken]
  );

  const startChat = (conversationUniqueName: any) => {
    setOpen(true);
    console.log(
      "conversationUniqueNameconversationUniqueName",
      conversationUniqueName
    );
    updateUniqueNameC(conversationUniqueName || "testtt");
  };

  const sendInvite = useCallback(
    (participantNumber, participantFullname = "") => {
      if (authToken) {
        fetch(
          `${process.env.REACT_APP_API_URL}api/send-video-invite`,

          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: `${authToken}`
            },
            body: JSON.stringify({
              // user_id: item.id,
              participant_number: participantNumber,
              participant_fullname: participantFullname
            })
          }
        )
          .then(response => response.json())
          .then(data => {
            alert.show("Invitation has been sent", {
              timeout: 2000, // custom timeout just for this one alert
              type: "success"
            });
            //props.startLoading(false);
            // setConversationList(data);
          });
      }
    },
    [authToken]
  );

  // useEffect(() => {
  //   getuserList();
  // }, []);

  useEffect(() => {
    if (updateUnreadMsg != "") {
      updateUnreadMessage(updateUnreadMsg);
      setUpdateUnreadMsg("");
    }
  }, [updateUnreadMsg]);

  useEffect(() => {
    if (restBtn) {
      setRestBtn(false);
      setFilterName("");
    }
    getuserList();
  }, [getuserList, restBtn]);

  /**
   * Call function to handle page navigation
   * @param {*} event
   * @param {*} value
   */
  const handleChangePage = (event: any, value: any) => {
    setPage(value);
  };

  /**
   * Call function to handle page size
   * @param {*} event
   * @param {*} value
   */
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
  };
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [showSmsBox, setShowSmsBox] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    setShowSmsBox(false);
    setSelectedConv(0);
  };
  // const location = useLocation<{ from: Location }>();
  // const [userName, setUserName] = useState('');
  // const [password, setPassword] = useState('');
  // const [authError, setAuthError] = useState<Error | null>(null);

  // const isAuthEnabled = Boolean(process.env.REACT_APP_SET_AUTH);
  useEffect(() => {
    if (!showSmsBox) {
      // setSelectedConv(0);
    }
  }, [showSmsBox]);

  const roomKey = localStorage.getItem("userRoomKey");
  const roomLinkOpen = "https://kyptestkit.com/room/" + roomKey + "";
  const roomLink = "/myroom/";
  // const login = () => {
  //   setAuthError(null);
  //   signIn?.(userName, password)
  //     .then(() => {
  //       history.replace(location?.state?.from || { pathname: '/' });
  //     })
  //     .catch(err => setAuthError(err));
  // };

  if (!isAuthReady) {
    return null;
  }

  console.log(
    "conversationListconversationListconversationList",
    conversationList,
    selectedConv
  );
  return (
    <>
      {
        loader ?
          <div className="loader-container">
            <div className="loader">
            </div>
          </div> : null
      }
      <div className={classes.background}>
        <UserMenu />
        <div className={classes.container}>
          <div className={classes.innerContainer}>
            <div className={classes.swooshContainer}>
              <div style={{ textAlign: "right" }} className="sub-menu-btns">
                <PhoneInput
                  className={classes.phonInput}
                  fullWidth
                  // disabled={disabled}
                  value={directPhone}
                  onChange={(eleme: any) => {
                    console.log("eleme", eleme);
                    setDirectPhone(eleme);
                  }}
                />

                <Link href={"#"}>
                  <Button
                    onClick={() => {
                      sendInvite(directPhone);
                    }}
                    className="whitespace-nowrap"
                    variant="contained"
                    color="secondary"
                    type="button"
                  >
                    Direct Invite
                  </Button>
                </Link>
                <Link
                  href={"#"}
                  onClick={() => {
                    navigator.clipboard.writeText(roomLinkOpen);

                    alert.show("Copied to clipboad", {
                      timeout: 2000, // custom timeout just for this one alert
                      type: "success"
                    });
                  }}
                >
                  <Button
                    className="whitespace-nowrap"
                    variant="contained"
                    color="secondary"
                    type="button"
                  >
                    Copy room Link to clipboard
                  </Button>
                </Link>
                <Link href={"user/add"}>
                  <Button
                    className="whitespace-nowrap"
                    variant="contained"
                    color="secondary"
                    type="button"
                  >
                    Add New Client
                  </Button>
                </Link>

                <Link href={"#"}>
                  <Button
                    onClick={() => {
                      setShowSmsBox(true);
                      console.log(showSmsBox, "herer");
                    }}
                    className="whitespace-nowrap"
                    variant="contained"
                    color="secondary"
                    type="button"
                  >
                    Send SMS
                  </Button>
                </Link>
              </div>
              <Typography variant="h5" className={classes.gutterBottom}>
                Conversation List
              </Typography>
              <form>

                <div className={classes.inputContainer}>
                  <div className={classes.textFieldContainer}>
                    <TextField
                      id="input-user-name"
                      variant="outlined"
                      fullWidth
                      size="small"
                      placeholder="First or Last name"
                      onChange={event => {
                        setFilterName(event.target.value);
                      }}
                      value={filterName}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      type="button"
                      id="search"
                      disabled={filterName === ""}
                      className={classes.continueButton}
                      onClick={e => {
                        searchConv(e);
                      }} // onSubmit={console.log('test')}
                    >
                      Search
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={e => {
                        setRestBtn(true);
                      }}
                      id="clear"
                      disabled={filterName === ""}
                      className={classes.continueButton}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              </form>
              <TableContainer>

                <Table
                  className="min-w-xl conversation-list-table"
                  aria-labelledby="tableTitle"
                >
                  <TableHead>
                    <TableRow className="h-64">
                      {/* <TableCell>Id</TableCell> */}
                      <TableCell>First name</TableCell>
                      <TableCell>Last Name</TableCell>
                      <TableCell>Participant Phone Number</TableCell>

                      {/* <TableCell>Conversations Id</TableCell> */}

                      {/* <TableCell>Conversation Name</TableCell> */}

                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  {
                    conversationList.length > 0
                      ?

                      <TableBody>
                        {conversationList?.map((iteam: any, index: any) => (
                          <TableRow
                            className="h-64"
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            {/* <TableCell>{iteam?.id}</TableCell> */}
                            <TableCell>{iteam?.firstname}</TableCell>
                            <TableCell>{iteam?.lastname}</TableCell>
                            <TableCell>{iteam?.receiver_phone}</TableCell>
                            {/* <TableCell>{iteam?.conversations_id}</TableCell>   */}
                            {/* <TableCell>{iteam?.conversation_name}</TableCell> */}

                            <TableCell>
                              <FormControl className={classes.butttonParent}>
                                <Button
                                  className="whitespace-nowrap"
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => {
                                    sendInvite(
                                      iteam.receiver_phone,
                                      iteam?.firstname + " " + iteam?.lastname
                                    );
                                  }}
                                  type="button"
                                >
                                  Send Invite
                                </Button>
                                <Link href={"/user/edit/" + iteam.id}>
                                  <Button
                                    className="whitespace-nowrap"
                                    variant="contained"
                                    color="secondary"
                                    type="button"
                                  >
                                    Edit
                                  </Button>
                                </Link>
                                <Link
                                  onClick={() => {
                                    deleteUserAction(iteam.id);
                                  }}
                                  href={"#"}
                                >
                                  <Button
                                    className="whitespace-nowrap"
                                    variant="contained"
                                    color="secondary"
                                    type="button"
                                  >
                                    Delete
                                  </Button>
                                </Link>
                                <Link
                                  className={classes.unreadIconMsg}
                                  onClick={() => {
                                    setSelectedConv(iteam.conversations_id);
                                    startChat(iteam.conv_unique_name);
                                    iteam.unreadMsg = false;
                                    setCurrentChatPerson(iteam.conversation_name);
                                  }}
                                  href={"#"}
                                >
                                  <Button
                                    className="whitespace-nowrap"
                                    variant="contained"
                                    color="secondary"
                                    type="button"
                                  >
                                    Send SMS
                                  </Button>
                                </Link>

                                <Link className={classes.messageBell}>
                                  {iteam.unreadMsg ? (
                                    <div
                                      className={classes.bellContainer}
                                      onClick={(event: any) => {
                                        setSelectedConv(iteam.conversations_id);
                                        startChat(iteam.conv_unique_name);
                                        iteam.unreadMsg = false;
                                        // setOpen(true);
                                        setCurrentChatPerson(
                                          iteam.conversation_name
                                        );
                                      }}
                                    >
                                      <BellIcon />
                                      <span className={classes.circle}>
                                        {/* {iteam.unreadMessage} */}
                                      </span>
                                    </div>
                                  ) : null}
                                </Link>

                                {/* <Link className={classes.unreadIconMsg}>
                                    <span className={"unreadMessags"}>5</span>
                                    <EmailIcon />
                                  </Link> */}
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      : <p>no record found</p>
                  }



                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                // count={conversationList?.count ? conversationList?.count : 0}
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              {/* <TablePagination
                count={conversationList?.count ? conversationList?.count : 0}
                component="div"
                rowsPerPage={rowsPerPage}
                page={page}
                rowsPerPageOptions={[10, 25, 100]}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              /> */}
            </div>
          </div>
        </div>
      </div>

      {open ? (
        <div>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            className="dailog-tests"
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
            maxWidth="md"
          // fullScreen={true}
          // paperFullWidth={true}
          >
            <AppBar sx={{ position: "relative" }} className={classes.chatTitle}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography2
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  {currentChatPerson}
                </Typography2>
              </Toolbar>
            </AppBar>

            <DialogTitle className={classes.dailogTitle}></DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {/* <UniqueNameContext.Provider
                  value={{ uniqueNameC, updateUniqueNameC }}
                > */}
                <ChatProvider>
                  <ChatWindow isChatWindowOpen={true} />
                </ChatProvider>
                {/* </UniqueNameContext.Provider> */}
              </DialogContentText>
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        </div>
      ) : null}

      {showSmsBox ? (
        <div>
          <Dialog
            open={showSmsBox}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            className="dailog-tests"
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
            maxWidth="sm"
          // fullScreen={true}
          // paperFullWidth={true}
          >
            <DialogTitle className={classes.dailogTitle}></DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
              <form onSubmit={handleSubmit}>
                <div className={""}>
                  <div className={classes.textFieldContainer}>
                    <PhoneInput
                      className={classes.phonInput}
                      fullWidth
                      value={elementSendSms.phone}
                      onChange={(eleme: any) => {
                        setElementSendSms({
                          phone: eleme,
                          message: elementSendSms.message
                        });
                      }}
                    />
                    <TextareaAutosize
                      id="input-user-name"
                      // variant="outlined"
                      minRows={2}
                      className={classes.textAreaElem}
                      placeholder={"Message"}
                      onChange={event => {
                        setElementSendSms({
                          phone: elementSendSms.phone,
                          message: event.target.value
                        });
                      }}
                      value={elementSendSms.message}
                    />
                    <Button
                      onClick={() => {
                        // sendDrirect();
                      }}
                      className={classes.sendSms}
                      variant="contained"
                      color="secondary"
                      type="submit"
                      disabled={
                        elementSendSms.message == "" ||
                        elementSendSms.phone == "" ||
                        elementSendSms.phone.length < 6
                      }
                    >
                      Send
                    </Button>
                  </div>
                </div>
              </form>
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        </div>
      ) : null}
    </>
  );
}
