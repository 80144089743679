import React from "react";

const WaitingListContext = React.createContext({
  waitingListC: "",
  updateWaitingListC: (data: any) => {}
});

export const RoutingProvider = WaitingListContext.Provider;
export const RoutingConsumer = WaitingListContext.Consumer;

export default WaitingListContext;
