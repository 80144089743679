import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext
} from "react";
import { Client } from "@twilio/conversations";
import { Conversation } from "@twilio/conversations/";
import { Message } from "@twilio/conversations/";
import AppStateProvider, { useAppState } from "../../state";
// import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import UniqueNameContextProvider from "../../UniqueNameContext";

type ChatContextType = {
  isChatWindowOpen: boolean;
  setIsChatWindowOpen: (isChatWindowOpen: boolean) => void;
  connect: (token: string) => void;
  hasUnreadMessages: boolean;
  messages: Message[];
  conversation: Conversation | null;
};

export const ChatContext = createContext<ChatContextType>(null!);

export const ChatProvider: React.FC = ({ children }) => {
  const { getToken, isFetching } = useAppState();
  // const { room, onError } = useVideoContext();
  const isChatWindowOpenRef = useRef(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [chatClient, setChatClient] = useState<Client>();
  const [chatStarted, setChatStarted] = useState(false);
  const { uniqueNameC, updateUniqueNameC } = useContext(
    UniqueNameContextProvider
  );
  const connect = useCallback((token: string) => {

    if (token != "" && token != undefined) {
      const client = new Client(token);
      const handleClientInitialized = (state: string) => {

        if (state === "initialized") {
          // @ts-ignore
          window.chatClient = client;
          setChatClient(client);

        } else if (state === "failed") {
          console.log(
            "There was a problem connecting to Twilio's conversation service."
          );
          // onError(new Error("There was a problem connecting to Twilio's conversation service."));
        }
      };
      client.on("initFailed", ({ error }) => {
        console.log("errrorr", error);
      });
      client.on("stateChanged", handleClientInitialized);

      return () => {
        client.off("stateChanged", handleClientInitialized);
      };
    }

  }, []);

  const handleJoin = () => {
    getToken("", "", "", "", "").then(
      ({ token, uniqueName, conversationUniqueName }) => {

        // videoConnect(token);
        // updateUniqueNameC(conversationUniqueName || "");
        process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== "true" &&
          connect(token);
        setChatStarted(true);
      }
    );
  };

  useEffect(() => {
    if (conversation) {
      const handleMessageAdded = (message: Message) =>
        setMessages(oldMessages => [...oldMessages, message]);
      conversation
        .getMessages()
        .then(newMessages => setMessages(newMessages.items));
      conversation.on("messageAdded", handleMessageAdded);
      return () => {
        conversation.off("messageAdded", handleMessageAdded);
      };
    } else {
      if (!chatStarted) {
        handleJoin();
      }
    }
    const token = localStorage.getItem("token");

    // connect(
    //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTSzdkODcwYTAxYmI5ZjRhMGYxOTAyYzkwYTQ0MzAwZjY5LTE2NjcyMzM3MzkiLCJncmFudHMiOnsiaWRlbnRpdHkiOiIzOSIsImNoYXQiOnsic2VydmljZV9zaWQiOiJJUzA1MWE0Yzg4NmY1MjQzNDU4NGQ5ODlmZTE5MmY2ZjE5In0sInZpZGVvIjp7InJvb20iOiJNYWRodWthciBSb29tIn19LCJpYXQiOjE2NjcyMzM3MzksImV4cCI6MTY2NzIzMzg1OSwiaXNzIjoiU0s3ZDg3MGEwMWJiOWY0YTBmMTkwMmM5MGE0NDMwMGY2OSIsInN1YiI6IkFDNzhjOWYyNGUzMWVjMjhlMjNmZjY2NTg4Y2IwMzNmODMifQ.xphh_COxnAmbiWpWkQc2SAHRb2NPeAisWelN889ef1Q'
    // );
  }, [conversation, handleJoin]);
  useEffect(() => {
    // If the chat window is closed and there are new messages, set hasUnreadMessages to true
    if (!isChatWindowOpenRef.current && messages.length) {
      setHasUnreadMessages(true);
    }
  }, [messages]);

  useEffect(() => {
    isChatWindowOpenRef.current = isChatWindowOpen;
    if (isChatWindowOpen) setHasUnreadMessages(false);
  }, [isChatWindowOpen]);

  useEffect(() => {

    // if (room && chatClient) {
    if (chatClient) {
      console.log("if condion sss2", uniqueNameC);
      chatClient
        .getConversationByUniqueName(uniqueNameC)
        .then(newConversation => {
          // newConversation.getMessagesCount().then((allmessage: any) => {
          newConversation.setAllMessagesRead().then(msg => { });
          // });
          //@ts-ignore
          window.chatConversation = newConversation;
          setConversation(newConversation);
        })
        .catch(e => {
          console.log("errors--->>", e);
          // onError(new Error('There was a problem getting the Conversation associated with this room.'));
        });
    }
  }, [chatClient]);

  return (
    <ChatContext.Provider
      value={{
        isChatWindowOpen,
        setIsChatWindowOpen,
        connect,
        hasUnreadMessages,
        messages,
        conversation
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
