import React, { useContext, useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import MessageList from "../MessageList/MessageList";
import ChatInput from "../ChatInput/ChatInput";
import useChatContext from "../../../hooks/useChatContext/useChatContext";
import UniqueNameContextProvider from "../../../UniqueNameContext";
import { error } from "console";
import { useParams } from "react-router-dom";
import TokenContextProvider from '../../../TokenContext'
import usePasscodeAuth, { fetchToken } from "../../../state/usePasscodeAuth/usePasscodeAuth";
import DirectUseChatContext from "../../../hooks/useChatContext/DirectChatContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chatWindowContainer: {
      background: "#FFFFFF",
      zIndex: 9,
      display: "flex",
      flexDirection: "column",
      borderLeft: "1px solid #E4E7E9",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 100
      }
    },
    hide: {
      display: "none"
    }
  })
);

// In this component, we are toggling the visibility of the ChatWindow with CSS instead of
// conditionally rendering the component in the DOM. This is done so that the ChatWindow is
// not unmounted while a file upload is in progress.

interface ChatWindowProps {
  isChatWindowOpen: boolean;
}

export default function ConversationWindow({ isChatWindowOpen }: ChatWindowProps) {
  const [currentChatPerson, setCurrentChatPerson] = useState<string>();
  const { conversationToken, groupId } = useParams<{ conversationToken: string, groupId: string }>();

  const { uniqueNameC, updateUniqueNameC } = useContext(
    UniqueNameContextProvider
  );
  const { updateAuthToken, authToken } = useContext(TokenContextProvider);

  const fetchData = () => {

    fetch(
      `${process.env.REACT_APP_API_URL}api/conversation-single-list/${conversationToken}/${groupId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      }
    )
      .then((res) => res.json())
      .then((json) => {
        updateUniqueNameC(json?.data)
        updateAuthToken(json?.consToken)

      }).catch(error =>
        console.log(error))
  }

  // useEffect(() => {
  //   fetchData()
  // }, [conversationToken])

  const classes = useStyles();
  const { messages, conversation } = DirectUseChatContext();

  return (
    <aside
      className={clsx(classes.chatWindowContainer, {
        [classes.hide]: !isChatWindowOpen
      })}
    >
      {/* <ChatWindowHeader /> */}
      <MessageList messages={messages} />
      <ChatInput
        conversation={conversation!}
        isChatWindowOpen={isChatWindowOpen}
      />
    </aside>
  );
}
