import * as React from "react";
import { useState, useRef, useCallback, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Theme, Button } from "@material-ui/core";
import useParticipants from "../../hooks/useParticipants/useParticipants";
import WaitingListContext from "../../WitingListContext";
import TokenContextProvider from "../../TokenContext";
import { SyncClient } from "twilio-sync";
import CallIcon from "@mui/icons-material/Call";
import CallEndIcon from "@mui/icons-material/CallEnd";
import { Participant, Room } from "twilio-video";
import ParticipantRowIcon from "../ParticipantRowIcon/ParticipantRowIcon";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

function stringAvatar(name: string) {
  name = name.toUpperCase();
  return {
    sx: {
      bgcolor: stringToColor(name)
    },
    children: name.includes(" ")
      ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
      : `${name[0]}`
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    fontSize: "0.87rem !important",
    paddingTop: "5%",
    paddingLeft: "10px"
  },
  heading: {
    marginTop: "0.875rem  !important",
    paddingLeft: "20px",
    fontSize: "17px !important ",
    borderBottom: "3px solid #80808066",
    paddingBottom: "10px"
  },
  disapproveBtn: {
    "&:hover": { background: "red" },
    background: "red",
    color: "#fff",
    padding: "0px 6px",
    marginLeft: "10px",
    borderRadius: "30px",
    minWidth: "auto"
  },
  listStyles: {
    padding: "10px 18px !important",
    maxHeight: "35vh",
    overflowY: "scroll"
  },
  approveBtn: {
    "&:hover": { background: "green" },
    background: "green",
    padding: "0px 6px",
    color: "#fff",

    minWidth: "auto",
    borderRadius: "30px"
  },
  boxBg: {
    color: "#fff",
    backgroundColor: "#201e1e !important",
    width: "100%",
    minHeight: "200px"
  },
  participantContainer: {
    minWidth: "15rem",
    backgroundColor: "black",
    borderLeft: "5px solid #80808066"
  },
  avatar: {
    width: "30px !important",
    height: "30px !important",
    fontSize: "13px !important"
  },
  rowContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px solid #80808066",
    padding: "10px 0",
    alignContent: "center"
  },
  iconContainer: {
    display: "flex"
  },
  nameContainer: {
    display: "flex"
  }
}));

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper
}));
interface MainParticipantInfoProps {
  participant: Participant;

  children?: React.ReactNode;
}

export default function ParticipantInCall({
  participant
}: MainParticipantInfoProps) {
  const participants = useParticipants();

  const classes = useStyles();

  const { waitingListC, updateWaitingListC } = useContext(WaitingListContext);

  const [open, setOpen] = React.useState(false);
  const [syncObject, setSyncObject] = useState<any>(null);
  const [newRequest, setNewRequest] = useState<any>([]);

  const roomName: any =
    localStorage?.getItem("roomName") != ""
      ? localStorage?.getItem("roomName")
      : "test";
  const rejectInvitations = useCallback(
    (index: any, rowIndex: any) => {
      // syncObject.list(roomName).then(function(list: any) {
      const mutatorFunction = (currentValue: any) => {
        currentValue.status = 2;
        return currentValue;
      };
      syncObject
        .mutate(index, mutatorFunction, { ttl: 86400 })
        .then((item: any) => {
          setNewRequest((current: any) =>
            current.filter((element: any, index: any) => {
              return index !== rowIndex;
            })
          );
        })
        .catch((error: any) => {
          console.error("List Item mutate() failed", error);
        });
    },
    [syncObject, setNewRequest, newRequest]
  );

  const waiting_lobby_list = (action: string, indexValue: any) => {
    return fetch(
      `${process.env.REACT_APP_API_URL}api/respond-to-video-call-request`,

      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `${authToken}`
        },
        body: JSON.stringify({
          action: action,
          roomName: roomName,
          index: indexValue.descriptor.index,
          requestBy: indexValue.descriptor.data.name
        }),
      }
    )
      .then(response => response.json())
      .then(data => {
        console.log('waiting lobby read success', data);
      })


  }


  const acceptInvitations = useCallback(
    (index: any, rowIndex: any) => {
      const mutatorFunction = (currentValue: any) => {
        currentValue.status = 1;
        return currentValue;
      };
      syncObject
        .mutate(index, mutatorFunction, { ttl: 86400 })
        .then((item: any) => {
          setNewRequest((current: any) =>
            current.filter((element: any, index: any) => {
              return index !== rowIndex;
            })
          );
        })
        .catch((error: any) => {
          console.error("List Item mutate() failed", error);
        });
      // });
    },
    [syncObject, newRequest, setNewRequest]
  );

  const [isListSubscribed, setListSubscribed] = useState(false);

  const { authToken } = useContext(TokenContextProvider);
  // const handleSignOut = useCallback(() => {
  //   localTracks.forEach((track) => track.stop());
  //   signOut?.();
  // }, [localTracks, signOut]);

  const subsList = () => {
    const URLRoomToken =
      localStorage?.getItem("URLRoomToken") != ""
        ? localStorage?.getItem("URLRoomToken")
        : "";
    setListSubscribed(true);
    if (
      URLRoomToken &&
      URLRoomToken != "" &&
      URLRoomToken != "undefined" &&
      URLRoomToken != "null"
    ) {
      const roomName: any =
        localStorage?.getItem("roomName") != ""
          ? localStorage?.getItem("roomName")
          : "";
      const syncTokenString: any =
        localStorage?.getItem("syncToken") != ""
          ? localStorage?.getItem("syncToken")
          : "";
      const syncClient2 = new SyncClient(syncTokenString);

      syncClient2
        .list(roomName)
        .then(function (list) {
          console.log("listtt video ", list);
          // list.removeList();
          setSyncObject(list);
          const pageHandler = (paginator: any) => {
            paginator.items.forEach((item: any) => {
              if (!item.isLocal && item.data.status == 0) {
                setNewRequest((oldd: any) => [...oldd, item]);
                setOpen(true);
              } else {
                list.remove(item.index);
              }
            });
            return paginator.hasNextPage
              ? paginator.nextPage().then(pageHandler)
              : null;
          };
          list
            .getItems({ from: 0, order: "asc", limit: 10 })
            .then(pageHandler)
            .catch(error => {
              console.error("List getItems() failed", error);
            });

          list.on("itemAdded", args => {
            console.log("newton", args.item, "hello kid");
            if (!args.isLocal) {
              updateWaitingListC(args.item.descriptor.data.name);
              setNewRequest((oldd: any) => {
                return [...oldd, args.item];
              });
            }
            setOpen(true);
          });
          list.on("itemRemoved", args => {
            console.log("newton", args.item, "hello kid");
            setNewRequest((current: any) => {
              return current.filter((element: any, index: any) => {
                return element.index !== args.index;
              });
            });
          });
        })
        .catch(function (error) { });
    } else {
      fetch(
        `${process.env.REACT_APP_API_URL}api/sync/token`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `${authToken}`
          }
        }
      )
        .then(response => response.json())
        .then(data => {
          const roomName: any =
            localStorage?.getItem("roomName") != ""
              ? localStorage?.getItem("roomName")
              : "test";
          //props.startLoading(false);
          const syncClient2 = new SyncClient(data.token);

          syncClient2
            .list(roomName)
            .then(function (list) {
              // list.removeList();
              setSyncObject(list);
              const pageHandler = (paginator: any) => {
                paginator.items.forEach((item: any) => {
                  if (!item.isLocal && item.data.status == 0) {
                    setNewRequest((oldd: any) => [...oldd, item]);
                    setOpen(true);
                  } else {
                    list.remove(item.index);
                  }
                });
                return paginator.hasNextPage
                  ? paginator.nextPage().then(pageHandler)
                  : null;
              };
              list
                .getItems({ from: 0, order: "asc", limit: 10 })
                .then(pageHandler)
                .catch(error => { });

              list.on("itemAdded", args => {
                if (!args.isLocal) {
                  setNewRequest((oldd: any) => [...oldd, args.item]);
                  updateWaitingListC(args.item.descriptor.data.name);
                }
                setOpen(true);
              });
              list.on("itemRemoved", args => {
                setNewRequest((current: any) => {
                  return current.filter((element: any, index: any) => {
                    return element.index !== args.index;
                  });
                });
              });
            })
            .catch(function (error) { });
        });
    }
  };



  useEffect(() => {
    if (!isListSubscribed) {
      subsList();
    }
    if (!newRequest || (newRequest && newRequest?.length < 1)) {
      setOpen(false);
    }
  }, [
    subsList,
    setOpen,
    newRequest
    // setNewRequest,
  ]);



  if (process.env.REACT_APP_SET_AUTH === "passcode") {
    return (
      <div className={classes.participantContainer}>
        <Box sx={{ flexGrow: 1, mb: 4 }}>
          <Grid container>
            <Demo className={classes.boxBg}>
              <Typography
                className={classes.heading}
                variant="h6"
                component="div"
              >
                Waiting Room
              </Typography>

              <List className={classes.listStyles}>
                {newRequest &&
                  newRequest.map((item: any, ind: any) => (
                    <div className={classes.rowContainer}>
                      <div className={classes.nameContainer}>
                        <Avatar
                          {...stringAvatar(`${item.data.name}`)}
                          className={classes.avatar}
                        />

                        <Typography className={classes.listItem} variant="h6">
                          {item.data.name}
                        </Typography>
                      </div>
                      <div className={classes.iconContainer}>
                        <Button
                          size="small"
                          className={classes.approveBtn}
                          onClick={() => {
                            acceptInvitations(item.index, ind)
                            waiting_lobby_list("Accepted", item)
                          }}
                        >
                          <CallIcon style={{ fontSize: "large" }} />
                        </Button>
                        <Button
                          size="small"
                          className={classes.disapproveBtn}
                          onClick={() => {
                            rejectInvitations(item.index, ind)
                            waiting_lobby_list("Rejected", item)
                          }}
                        >
                          <CallEndIcon style={{ fontSize: "large" }} />
                        </Button>
                      </div>
                    </div>
                  ))}
              </List>
            </Demo>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Demo className={classes.boxBg}>
              <Typography
                className={classes.heading}
                variant="h6"
                component="div"
              >
                Participants
              </Typography>
              <List className={classes.listStyles}>
                {participants.map((participant, index) => (
                  <ParticipantRowIcon
                    key={index} participant={participant} />
                ))}
              </List>
            </Demo>
          </Grid>
        </Box>
      </div>
    );
  }

  return null;
}
