import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAppState } from "../../state";
import { useHistory } from "react-router-dom";

export default function PrivateRouteAdmin({ children, ...rest }: RouteProps) {
  const { isAuthReady, user } = useAppState();
  const history = useHistory();

  const renderChildren = user || !process.env.REACT_APP_SET_AUTH;
  if (!renderChildren && !isAuthReady) {
    return null;
  }
  if (renderChildren && localStorage.getItem("userRole") != "Admin") {
    history.push("/conversation/dashboard");
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        renderChildren ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
