import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext
} from "react";
import { Client } from "@twilio/conversations";
import { Conversation } from "@twilio/conversations/";
import { Message } from "@twilio/conversations/";
import UniqueNameContextProvider from '../../../UniqueNameContext'
import { useAppState } from "../../../state";
import { useParams } from "react-router-dom";


type ChatContextType = {
  isChatWindowOpen: boolean;
  setIsChatWindowOpen: (isChatWindowOpen: boolean) => void;
  connect: (token: string) => void;
  hasUnreadMessages: boolean;
  messages: Message[];
  conversation: Conversation | null;
};

export const DirectChatContext = createContext<ChatContextType>(null!);

export const DirectChatProvider: React.FC = ({ children }) => {
  const isChatWindowOpenRef = useRef(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [chatClient, setChatClient] = useState<Client>();
  const [chatStarted, setChatStarted] = useState(false);
  const { uniqueNameC, updateUniqueNameC } = useContext(
    UniqueNameContextProvider
  );
  const { conversationToken, groupId } = useParams<{ conversationToken: string, groupId: string }>();


  const connect = useCallback((token: string) => {
    if (token != "" && token != undefined) {
      const client = new Client(token);
      const handleClientInitialized = (state: string) => {

        if (state === "initialized") {
          // @ts-ignore
          window.chatClient = client;
          setChatClient(client);

        } else if (state === "failed") {
          console.log(
            "There was a problem connecting to Twilio's conversation service."
          );
          // onError(new Error("There was a problem connecting to Twilio's conversation service."));
        }
      };
      client.on("initFailed", ({ error }) => {
        console.log("errrorr", error);
      });
      client.on("stateChanged", handleClientInitialized);

      return () => {
        client.off("stateChanged", handleClientInitialized);
      };
    }

  }, []);
  const handleJoin = () => {

    fetch(
      `${process.env.REACT_APP_API_URL}api/conversation-single-list/${conversationToken}/${groupId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log('====================================');
        console.log(json.consToken);
        console.log('====================================');
        updateUniqueNameC(json?.data)
        connect(json.consToken)
      }).catch(error =>
        console.log(error))
  };



  useEffect(() => {
    handleJoin()
  }, [conversationToken])


  useEffect(() => {
    if (conversation) {
      const handleMessageAdded = (message: Message) =>
        setMessages(oldMessages => [...oldMessages, message]);
      conversation
        .getMessages()
        .then(newMessages => setMessages(newMessages.items));
      conversation.on("messageAdded", handleMessageAdded);
      return () => {
        conversation.off("messageAdded", handleMessageAdded);
      };
    }
    else {
      if (!chatStarted) {
        handleJoin();
      }
    }

  }, [conversation]);

  useEffect(() => {
    // If the chat window is closed and there are new messages, set hasUnreadMessages to true
    if (!isChatWindowOpenRef.current && messages.length) {
      setHasUnreadMessages(true);
    }
  }, [messages]);

  useEffect(() => {
    isChatWindowOpenRef.current = isChatWindowOpen;
    if (isChatWindowOpen) setHasUnreadMessages(false);
  }, [isChatWindowOpen]);

  useEffect(() => {

    // if (room && chatClient) {
    if (chatClient) {
      console.log("if condion sss", uniqueNameC);
      chatClient
        .getConversationByUniqueName(uniqueNameC)
        .then(newConversation => {
          // newConversation.getMessagesCount().then((allmessage: any) => {
          newConversation.setAllMessagesRead().then(msg => { });
          // });
          //@ts-ignore
          window.chatConversation = newConversation;
          setConversation(newConversation);
        })
        .catch(e => {
          console.log("errors--->>", e);
          // onError(new Error('There was a problem getting the Conversation associated with this room.'));
        });
    }
  }, [chatClient]);

  return (
    <DirectChatContext.Provider
      value={{
        isChatWindowOpen,
        setIsChatWindowOpen,
        connect,
        hasUnreadMessages,
        messages,
        conversation
      }}
    >
      {children}
    </DirectChatContext.Provider>
  );
};

